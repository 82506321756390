<template>
	<div class="page">
		<el-main v-show="!noticeModelShow">
			<el-container>
				<el-row>
					<el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form :inline="true">
							<el-form-item>
								<el-select v-model="selectNoticeTarget" placeholder="请选择人员类型" style="width: 100px" @change="SearchPage()" size="medium">
									<el-option v-for="(item, i) in selectNoticeTargetData" :key="i" :label="item.title" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width: 300px; margin-right: 10px" size="medium">
								<template #prepend>
									<el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 120px">
										<el-option v-for="(item, i) in dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
									</el-select>
								</template>
							</el-input>
							<slot name="searchSlot"></slot>
							<el-form-item>
								<el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage()">查询</el-button>
								<el-button type="primary" icon="el-icon-plus" size="medium" v-has="'Add'" @click="noticeAdd">创建通知</el-button>
							</el-form-item>
							<el-form-item>
								<el-popover placement="bottom" :width="400" trigger="click">
									<template #reference>
										<el-button size="medium"><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
									</template>
									<el-checkbox-group v-model="dataTableSearchList">
										<div v-for="(item, i) in dataTableList" :key="i">
											<el-checkbox v-if="item.visible != false" :label="item" checked style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
											<el-checkbox v-else :label="item" style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
										</div>
									</el-checkbox-group>
									<el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
									<el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
								</el-popover>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-table size="small" border ref="multipleTable" :data="dataTable" style="width: 99%" max-height="700" :height="tableHeight" :fit="true" @selection-change="handleSelectionChange">
							<el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
							<el-table-column label="操作" fixed="right" header-align="center" align="center" width="300">
								<template #default="scope">
									<el-button size="mini" v-has="'QueryByPage'" @click="noticeTarget(scope.row.Id, scope.row.Title)">详情</el-button>
									<el-button v-has="'Update'" size="mini" @click="noticeEdit(scope.row.Id)">编辑</el-button>
									<el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="noticeDelete(scope.row.Id)">
										<template #reference>
											<el-button type="danger" v-has="'FakeDelete'" size="mini">删除</el-button>
										</template>
									</el-popconfirm>
								</template>
							</el-table-column>
							<el-table-column v-for="(col, index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
								<template #default="scope">
									<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
									<el-tag size="medium" v-else-if="col.type == 'enum'">
										{{ col.formatters(scope.row[col.attr]) }}
									</el-tag>
									<label v-else>{{ scope.row[col.attr] }}</label>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<paging :currentPage="currentPage" :pagesize="pageSize" :total="total" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange" :pagesizes="pageSizes" />
					</el-col>
				</el-row>
			</el-container>
			<el-dialog v-model="dialogVisible" :title="dialogTitle" width="70%" :before-close="handleClose" destroy-on-close center>
				<noticeTarget @operationSuccess="operationSuccess" :noticeId="noticeId" />
			</el-dialog>
		</el-main>
		<el-main v-show="noticeModelShow" style="height: 80%;max-height: 80%;">
			<el-card shadow="never" :header="noticeModelTitle">
				<el-steps :active="stepActive" align-center v-show="noticeForm.Id == 0">
					<el-step title="内容" description="编辑通知内容" />
					<el-step title="人员" description="选择通知人员" />
				</el-steps>
				<el-row>
					<el-col :lg="{ span: 16, offset: 4 }">
						<el-form v-show="stepActive == 0" ref="noticeForm" :model="noticeForm" :rules="noticeFormRules" label-position="top">
							<el-form-item label="标题" prop="Title">
								<el-input v-model="noticeForm.Title" placeholder="请输入通知标题" clearable></el-input>
							</el-form-item>
							<el-form-item label="内容" prop="Content"> </el-form-item>
							<vue-ueditor-wrap v-model="noticeForm.Content" :config="editorConfig" editor-id="noticeUeditor" :ready="onEditorReady" max-height="750" placeholder="请输入通知内容" />
						</el-form>
						<div v-if="stepActive == 1">
							<div v-if="selectNoticeTarget == 0">
								<el-header style="height: auto;">
									<sc-select-filter :data="filterData" :label-width="80" :selectedValues="checkFilter" @on-change="filterChange"></sc-select-filter>
								</el-header>
								<el-header style="height: auto;">
									<el-table size="small" border ref="multipleTable" :data="studentDataTable" style="width: 100%" max-height="600" :height="tableHeight" :fit="true" @selection-change="selectionStudentChange">
										<el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
										<el-table-column v-for="(col, index) in studentDataTableConfig.filter((it) => it.isDisplay != false)" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
											<template #default="scope">
												<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
												<el-tag size="medium" v-else-if="col.type == 'enum'">
													{{ col.formatters(scope.row[col.attr]) }}
												</el-tag>
												<label v-else>{{ scope.row[col.attr] }}</label>
											</template>
										</el-table-column>
									</el-table>
								</el-header>
							</div>
							<div v-if="selectNoticeTarget == 1">
								<el-header style="height: auto;">
									<sc-select-filter :data="workerFilterData" :label-width="80" @on-change="workerfilterChange"></sc-select-filter>
								</el-header>
								<el-header style="height: auto;">
									<el-table size="small" border ref="multipleTable" :data="workerDataTable" style="width: 100%" max-height="600" :height="tableHeight" :fit="true" @selection-change="selectionWorkerChange">
										<el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
										<el-table-column v-for="(col, index) in workerDataTableConfig.filter((it) => it.isDisplay != false)" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
											<template #default="scope">
												<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
												<el-tag size="medium" v-else-if="col.type == 'enum'">
													{{ col.formatters(scope.row[col.attr]) }}
												</el-tag>
												<label v-else>{{ scope.row[col.attr] }}</label>
											</template>
										</el-table-column>
									</el-table>
								</el-header>
							</div>
						</div>
						<div style="margin-top: 20px;display: flex;justify-content: center;">
							<el-button @click="noticeModelShow = false">取消</el-button>
							<el-button v-if="stepActive > 0 && stepActive < 2" @click="pre" :disabled="submitLoading">上一步</el-button>
							<el-button v-if="stepActive < 1 && noticeForm.Id == 0" type="primary" @click="next">下一步</el-button>
							<el-button v-if="stepActive == 1 || noticeForm.Id != 0" type="primary" @click="saveNotice('noticeForm')" :loading="submitLoading">提交</el-button>
						</div>
					</el-col>
				</el-row>
			</el-card>
		</el-main>
	</div>
</template>
<script>
const UEDITOR_HOME_URL = process.env.NODE_ENV === "development" ? "/UEditor/" : `/UEditor/`;
import noticeTarget from "./noticeTarget";
import scSelectFilter from "@/components/scSelectFilter";
let _this;
export default {
	name: "notice",
	components: {
		noticeTarget,
		scSelectFilter,
	},
	data() {
		return {
			editorConfig: {
				// 上传文件接口（
				serverUrl: `${this.$CONFIG.API_UEditor_Upload_URL}`,
				// 访问 UEditor 静态资源的根路径，开发环境是在 http://192.168.3.200:8081/UEditor/，部署到 GitHub Pages 之后的路径就是 /vue-ueditor-wrap/UEditor/ 了
				UEDITOR_HOME_URL,
				// 自定义列表标号图片的地址，默认是 http://bs.baidu.com/listicon/，不过默认链接下的列表小图片都已经 404 了，所以下载了一份放到项目里啦
				listiconpath: `${UEDITOR_HOME_URL}listicon/`,
			},
			noticeId: 0,
			selectNoticeTarget: 0,
			selectNoticeTargetData: [
				{ title: "学生", value: 0 },
				{ title: "教职工", value: 1 },
			],
			noticeModelShow: false,
			noticeModelTitle: "",
			noticeForm: {
				Id: 0,
				Title: "",
				Content: "",
			},
			noticeFormRules: {
				Title: [
					{
						required: true,
						message: "请输入通知标题",
						trigger: "blur",
					},
					{
						min: 2,
						max: 100,
						message: "长度在2 到 100 个字符",
						trigger: "blur",
					},
				],
				Content: [
					{
						required: true,
						message: "请输入通知内容",
						trigger: "blur",
					},
				],
			},
			stepActive: 0,
			submitLoading: false,
			filterData: [
				{
					title: "学生类型",
					key: "studentType",
					multiple: true,
					options: [
						{
							label: "全部",
							value: "",
						},
						{
							label: "走读生",
							value: "0",
						},
						{
							label: "半走读",
							value: "1",
						},
						{
							label: "住校生",
							value: "2",
						},
					],
				},
				{
					title: "年级",
					key: "grade",
					multiple: true,
					options: [
						{
							label: "全部",
							value: "",
						},
					],
				},
				{
					title: "班级",
					key: "class",
					multiple: true,
					options: [
						{
							label: "全部",
							value: "",
						},
					],
				},
			],
			checkFilter: {},
			checkStudentTypes: "",
			checkGrades: "",
			checkClasses: "",
			checkStudents: "",
			studentDataTable: [],
			selectionStudent: [],
			studentDataTableConfig: [
				{
					label: "Id",
					attr: "Id",
					isDisplay: false,
				},
				{
					label: "班级",
					attr: "ClassName",
				},
				{
					label: "姓名",
					attr: "StudentName",
				},
				{
					label: "照片",
					attr: "StudentPhotoUrl",
					type: "img",
				},
				{
					label: "类型",
					attr: "StudentType",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 0:
								result = "走读生";
								break;
							case 1:
								result = "半走读";
								break;
							case 2:
								result = "住校生";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
			],
			workerFilterData: [
				{
					title: "类型",
					key: "targettype",
					multiple: false,
					options: [
						{
							label: "群发",
							value: "",
						},
						{
							label: "按角色",
							value: 0,
						},
						{
							label: "按班主任",
							value: 1,
						},
					],
				},
				{
					title: "人员",
					key: "target",
					multiple: false,
					options: [
						{
							label: "全部",
							value: "",
						},
					],
				},
			],
			workerDataTable: [],
			selectionWorker: [],
			workerDataTableConfig: [
				{
					label: "Id",
					attr: "Id",
					isDisplay: false,
				},
				{
					label: "姓名",
					attr: "AdminName",
				},
				{
					label: "照片",
					attr: "AdminAvatarUrl",
					type: "img",
				},
				{
					label: "手机号",
					attr: "AdminPhoneAccount",
				},
				{
					label: "性别",
					attr: "Sex",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 1:
								result = "男";
								break;
							case 2:
								result = "女";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{
					label: "备注",
					attr: "RemarkInfo",
				},
			],
			dialogVisible: false,
			total: 0, //数据总条数
			pageSize: 10, //当前显示数据数量
			pageSizes: [10, 20, 50, 100],
			currentPage: 1, //分页当前页
			keyword: "", //搜索输入关键字
			checkInput: "", //选择所需要查询的内容
			dataConfig: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
					isDisplay: false,
				},
				{
					label: "标题",
					attr: "Title",
					isSearch: true,
				},
				{ label: "内容", attr: "Content", isSearch: true },
				{
					label: "来源",
					attr: "NoticeSource",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 0:
								result = "系统";
								break;
							case 1:
								result = "微信";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{
					label: "作者",
					attr: "AuthName",
					isInput: false,
					isSearch: true,
				},
				{
					label: "创建时间",
					attr: "CreatedTime",
					isInput: false,
					visible: false,
				},
				{
					label: "更新时间",
					attr: "UpdatedTime",
					isInput: false,
					visible: false,
				},
			],
			dataTableSearch: [], //搜索信息
			dataTableList: [], //显示列信息
			dataTableSearchList: [], //列筛选信息
			dataTable: [], //显示数据
			multipleSelection: [], //选中数据
			tableHeight: 200, //table高度
		};
	},
	mounted() {
		_this = this;
		_this.$nextTick(function() {
			// 仅在整个视图都被渲染之后才会运行的代码
			//设置table位置
			let heightStyle = window.innerHeight - 230;
			_this.tableHeight = heightStyle;

			//窗口大小改变时，table 高度设置
			window.onresize = () => {
				return (() => {
					let heightStyle = window.innerHeight - 230;
					_this.tableHeight = heightStyle;
				})();
			};
		});
		_this.SearchPage();
		_this.dataTableSearch = _this.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
		_this.dataTableList = _this.dataConfig.filter((it) => it.isDisplay != false); //可显示列
	},
	methods: {
		//页面搜索查询
		SearchPage() {
			let json = {
				searchValue: "",
				pageNo: _this.currentPage,
				pageSize: _this.pageSize,
				searchBeginTime: _this.searchStartTime,
				searchEndTime: _this.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
				NoticeTarget: _this.selectNoticeTarget,
			};
			if (_this.checkInput.length > 0 && _this.keyword != "") {
				var searchParameters = [],
					input = _this.keyword;
				for (let index = 0; index < _this.checkInput.length; index++) {
					const element = _this.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}

			_this.$API.SysNotice.QueryByPage.post(json).then((res) => {
				if (res) {
					_this.total = res.TotalRows;
					_this.pagesize = res.PageSize;
					_this.currentPage = res.PageNo;
					_this.dataTable = res.Rows;
				}
			});
		},
		handleSizeChange(val) {
			_this.pageSize = val;
			_this.SearchPage();
		},
		handleCurrentChange(val) {
			_this.currentPage = val;
			_this.SearchPage();
		},
		handleSelectionChange(val) {
			_this.multipleSelection = val;
		},
		// 列筛选
		filterFunHandle(type, currentItem, index) {
			if (type === "allchecked") {
				// 全选
				_this.dataTableSearchList = _this.dataConfig.filter((it) => it.isDisplay != false);
			} else if (type === "cancel") {
				// 取消全选
				_this.dataTableSearchList = []; // 复选框置为空，全部不选择
			}
		},
		deleteNotice() {
			if (!_this.multipleSelection || _this.multipleSelection.length <= 0) {
				_this.$message.warning("请选择需要删除的通知！");
				return;
			}
			var ids = new Array();
			var msgStr = "";
			_this.multipleSelection.forEach((it) => {
				ids.push(it.Id);
				msgStr += `【${it.Title}】`;
			});
			_this
				.$confirm(`确认删除${msgStr}等${_this.multipleSelection.length}个通知信息吗？`)
				.then(() => {
					_this.$API.SysNotice.FakeDelete.delete(ids).then((res) => {
						if (res.Success) {
							_this.$message.success(res.Message);
							_this.SearchPage();
						}
					});
				})
				.catch(() => {});
		},
		noticeDelete(id) {
			_this.$API.SysNotice.FakeDelete.delete([id]).then((res) => {
				if (res.Success) {
					_this.$message.success(res.Message);
					_this.SearchPage();
				}
			});
		},
		//初始化年级
		initCheckGradesData() {
			_this.$API.Classes.GetGradeList.get().then((res) => {
				var gradeArray = new Array();
				gradeArray.push({ label: "全部", value: "" });
				if (res && res.length > 0) {
					res.forEach((item, index) => {
						gradeArray.push({ label: item, value: item });
					});
				}
				_this.filterData[1].options = gradeArray;
			});
			_this.initCheckClassesData();
		},
		//初始化班级
		initCheckClassesData() {
			var data = {
				Grades: _this.checkGrades,
			};
			_this.$API.Classes.GetClassListByGrade.post(data).then((res) => {
				var classArray = new Array();
				classArray.push({ label: "全部", value: "" });
				if (res && res.length > 0) {
					res.forEach((item, index) => {
						classArray.push({ label: item.ClassName, value: item.Id });
					});
				}
				_this.filterData[2].options = classArray;
			});
			_this.initCheckStudentsData();
		},
		//初始化学生
		initCheckStudentsData() {
			var data = {
				ClassIds: _this.checkClasses,
				Grades: _this.checkGrades,
				StudentTypes: _this.checkStudentTypes,
			};
			_this.$API.Student.GetStudentsList.post(data).then((res) => {
				_this.studentDataTable = res;
				_this.selectionStudent = [];
			});
		},
		//选择学生
		selectionStudentChange(val) {
			_this.selectionStudent = val;
		},
		//初始化角色信息
		initRolesData() {
			_this.$API.Role.GetRoleAlls.post({}).then((res) => {
				var roleArray = new Array();
				roleArray.push({ label: "全部", value: "" });
				if (res && res.length > 0) {
					res.forEach((item, index) => {
						roleArray.push({ label: item.RoleName, value: item.Id });
					});
				}
				_this.workerFilterData[1].title = "角色";
				_this.workerFilterData[1].multiple = false;
				_this.workerFilterData[1].options = roleArray;
			});
		},
		//初始化角色职工信息
		initRolesAdmin(data) {
			_this.$API.Admin.GetAdminsByRoles.post(data).then((res) => {
				_this.selectionStudent = [];
				_this.workerDataTable = res.map((it) => ({ Id: it.Id, AdminName: it.AdminName, AdminAvatarUrl: it.AdminAvatarUrl, AdminPhoneAccount: it.AdminPhoneAccount, Sex: it.Sex, RemarkInfo: it.RoleName }));
			});
		},

		//选择教职工
		selectionWorkerChange(val) {
			_this.selectionWorker = val;
		},
		//详细
		noticeTarget(id, title) {
			var that = this;
			_this.dialogTitle = `【${title}】详情`;
			_this.noticeId = id;
			_this.dialogVisible = true;
		},
		//创建
		noticeAdd() {
			if (_this.selectNoticeTarget == 1) {
				_this.noticeModelTitle = "创建教职工通知";
			} else {
				_this.noticeModelTitle = "创建学生通知";
			}
			_this.checkGrades = "";
			_this.checkClasses = "";
			_this.checkStudentTypes = "";
			_this.selectionStudent = [];
			_this.selectionWorker = [];
			_this.stepActive = 0;
			_this.noticeId = 0;
			_this.noticeForm = {
				Id: 0,
				Title: "",
				Content: "",
			};
			_this.noticeModelShow = true;
		},
		//修改
		noticeEdit(id) {
			_this.noticeId = id;
			_this.noticeModelTitle = "修改通知";
			_this.$API.SysNotice.QueryById.get(id).then((res) => {
				if (res) {
					_this.noticeForm.Id = res.Id;
					_this.noticeForm.Title = res.Title;
					_this.noticeForm.Content = res.Content;
				} else {
					return;
				}
			});
			_this.checkGrades = "";
			_this.checkClasses = "";
			_this.checkStudentTypes = "";
			_this.selectionStudent = [];
			_this.selectionWorker = [];
			_this.stepActive = 0;
			_this.noticeModelShow = true;
		},
		//保存
		saveNotice(formName) {
			_this.$refs[formName].validate((valid) => {
				if (valid) {
					var data = {
						Id: _this.noticeForm.Id,
						Title: _this.noticeForm.Title,
						Content: _this.noticeForm.Content,
						Grades: _this.checkGrades,
						Classes: _this.checkClasses,
						StudentTypes: _this.checkStudentTypes,
						NoticeTarget: _this.selectNoticeTarget,
						NoticeSource: 0,
					};
					if (_this.selectNoticeTarget == 0) {
						data.PersonIds = _this.selectionStudent.map((it) => it.Id);
					} else if (_this.selectNoticeTarget == 1 && _this.noticeForm.Id == 0) {
						if (_this.selectionWorker.length <= 0) {
							_this.$message.warning("请勾选人员!");
							return;
						}
						var personids = _this.selectionWorker.map((it) => it.Id);
						data.PersonIds = [...new Set(personids)];
					}
					_this.submitLoading = true;
					const loading = _this.$loading({
						lock: true,
						text: "数据处理中",
						spinner: "el-icon-loading",
						background: "rgba(0, 0, 0, 0.7)",
					});
					_this.$API.SysNotice.AddOrUpdate.post(data).then((res) => {
						loading.close();
						_this.submitLoading = false;
						_this.noticeModelShow = false;
						if (res.Success) {
							_this.$message.success(res.Message);
							this.SearchPage();
						} else {
							_this.$message.error(res.Message);
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		next() {
			if (_this.stepActive == 0) {
				this.$refs["noticeForm"].validate((valid) => {
					if (valid) {
						this.stepActive += 1;
					} else {
						return false;
					}
				});
			}
			if (_this.stepActive == 1 && _this.selectNoticeTarget == 0) {
				_this.initCheckGradesData();
			}
			if (_this.stepActive == 1 && _this.selectNoticeTarget == 1) {
				_this.initworkerfilter();
			}
		},
		pre() {
			if (this.stepActive == 1) {
				_this.checkGrades = "";
				_this.checkClasses = "";
				_this.checkStudentTypes = "";
				_this.selectionStudent = [];
			}
			this.stepActive -= 1;
		},
		filterChange(data) {
			_this.checkStudentTypes = "";
			if (data.studentType != "") {
				_this.checkStudentTypes = data.studentType.split(",");
			}
			_this.checkGrades = "";
			if (data.grade != "") {
				_this.checkGrades = data.grade.split(",");
			}
			_this.checkClasses = "";
			if (data.class != "") {
				_this.checkClasses = data.class.split(",");
			}
			_this.initCheckClassesData();
		},
		initworkerfilter() {
			_this.workerFilterData[1].title = "人员";
			var roleArray = new Array();
			roleArray.push({ label: "全部", value: "" });
			_this.workerFilterData[1].options = roleArray;
			_this.initRolesAdmin({ roleIds: [] });
		},
		workerfilterChange(data) {
			if (data.targettype == "") {
				_this.initworkerfilter();
				return;
			}
			if (data.targettype == 0) {
				_this.initRolesData();
				var roleIds = [];
				if (data.target != "") {
					roleIds = data.target.split(",");
				}
				_this.initRolesAdmin({ roleIds });
			}
			if (data.targettype == 1) {
				_this.$API.Classes.GetClassListByGrade.post({ Grades: [] }).then((res) => {
					var classArray = new Array();
					classArray.push({ label: "全部", value: "" });
					if (res && res.length > 0) {
						res.forEach((item, index) => {
							classArray.push({ label: item.ClassName, value: item.Id });
						});
					}
					_this.workerFilterData[1].title = "班级";
					_this.workerFilterData[1].multiple = true;
					_this.workerFilterData[1].options = classArray;
				});
				var classIds = [];
				if (data.target != "") {
					classIds = data.target.split(",");
				}
				_this.$API.SysNotice.GetWokerDataByClassTeacher.post({ Ids: classIds }).then((res) => {
					_this.selectionStudent = [];
					_this.workerDataTable = res.map((it) => ({ Id: it.Id, AdminName: it.AdminName, AdminAvatarUrl: it.AdminAvatarUrl, AdminPhoneAccount: it.AdminPhoneAccount, Sex: it.Sex, RemarkInfo: it.RoleName }));
				});
			}
		},
		operationSuccess() {
			//操作成功后，关闭弹出框，刷新数据显示
			_this.dialogVisible = false;
			_this.noticeId = 0;
			_this.SearchPage();
		},
		handleClose(done) {
			_this
				.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => {});
		},
	},
};
</script>
<style scoped>
.page {
	margin: 5px;
	padding: 10px;
	min-height: 840px;
	width: 95%;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
	width: auto;
}
.drawerTitle {
	color: #409eff;
}
</style>
